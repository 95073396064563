import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';

import * as AuthService from '@services/auth';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE from '@utils/cookies/authCookie';
import { useUser } from '@hooks/user';
import { useTracking } from '@hooks/tracking';
import { useLoading } from '@hooks/loading';
import { useRouter } from 'next/router';

interface IAuthContextData {
  showModalLogin: boolean;
  loginURL: string;
  setShowModalLogin(
    value: boolean,
    signUp?: boolean,
    displaySmall?: boolean,
  ): void;
  logout(redirect?: string): void;
  showModalCompleted: boolean;
  setCompletedModal: (value: boolean) => void;
  userIsNotAllowedToRead: boolean;
  displaySmall: boolean;
  setUserIsNotAllowedToRead: (value: boolean) => void;
  signUp?: boolean;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { setUser } = useUser();
  const [showModalCompleted, setShowModalCompleted] = useState(false);
  const [userIsNotAllowedToRead, setUserIsNotAllowedToRead] = useState(false);
  const { identify } = useTracking();
  const { addLoading, removeLoading } = useLoading();
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [displaySmall, setDisplaySmall] = useState(false);
  const [signUp, setSignUp] = useState<boolean | undefined>();

  const { asPath } = useRouter();

  const currentURL = useMemo(
    () => process.env.NEXT_PUBLIC_APP + asPath,
    [asPath],
  );

  const loginURL = useMemo(
    () =>
      `${
        process.env.NEXT_PUBLIC_AUTH_CLIENT
      }authentication/signinV2?redirect_uri=${encodeURIComponent(
        currentURL,
      )}&client_view=platform`,
    [currentURL],
  );

  const logout = (): void => {
    cookies.remove(AUTH_TOKEN_COOKIE);

    if (window.location.href.includes('artigos/')) {
      localStorage.setItem('userJustLogIn', 'true');
    }

    window.location.href = `${
      process.env.NEXT_PUBLIC_AUTH_API
    }api/auth/signout?redirect_to=${encodeURIComponent(currentURL)}`;
  };

  const setCompletedModal = (value: boolean) => {
    setShowModalCompleted(value);
  };

  useEffect(() => {
    const validateLogged = async (): Promise<void> => {
      addLoading();

      try {
        const user = await AuthService.getUser();
        if (!user || !user._id) throw new Error();
        setUser(user);
        identify(user.email);
        if (!user.jobtitle || !user.phone) {
          setShowModalCompleted(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        removeLoading();
      }
    };

    if (
      !process.env.NEXT_PUBLIC_AUTH_API ||
      !process.env.NEXT_PUBLIC_AUTH_CLIENT
    )
      return;

    if (!cookies.get(AUTH_TOKEN_COOKIE)) return;

    validateLogged();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signUp,
        logout,
        loginURL,
        showModalLogin,
        showModalCompleted,
        setCompletedModal,
        userIsNotAllowedToRead,
        setUserIsNotAllowedToRead,
        displaySmall,
        setShowModalLogin: useCallback((show, up, small) => {
          setShowModalLogin(show);
          setSignUp(up);
          setDisplaySmall(small);
        }, []),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Auth must be used within a AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
