import { makeStyles, styled } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';

export const useStyles = makeStyles({
  bottom: {
    color: '#00000029',
    zIndex: 2,
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    zIndex: 2,
  },
  circle: {
    strokeLinecap: 'round',
  },
});

export const Label = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(66),
  fontWeight: 'bold',
  margin: 0,
  padding: 0,
  lineHeight: calcSizeBasedInVhScale(40),
  [theme.breakpoints.down('sm')]: {
    lineHeight: 'initial',
  },
}));

export const ContainerLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  '& span': {
    fontFamily: 'Barlow',
    fontWeight: 'bold',
    fontSize: calcSizeBasedInVhScale(17),
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      marginTop: 5,
    },
  },
}));
